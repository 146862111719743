import Vue from 'vue';
import copy from 'copy-to-clipboard'; //add plugin to prevent restrictions at safari

export const copyToClipBoard = (
  data: string,
  message?: string,
  noToast?: boolean
): void => {
  if (!data) return;
  copy(data);
  if (!noToast) Vue.prototype.$toast.success(message ?? 'Copied');
};
