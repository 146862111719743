import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import * as Sentry from '@sentry/vue';
import DatetimePicker from 'vuetify-datetime-picker';
import VNumericField from './components/NumericField.vue';
import VDataTableWithPagination from '@/components/v-data-table/VDataTableWithPagination.vue';
import NumeralSpaces from '@/filters/numeral-spaces';
import FormattedPrice from '@/filters/formatted-price';
import '@/styles/styles.scss';
import RolePermissions from '@/plugins/role-permissions';

export const eventBus = new Vue();

Vue.config.productionTip = false;
Vue.use(vueNumeralFilterInstaller, { locale: 'en' });
Vue.use(DatetimePicker);

Vue.component('VNumericField', VNumericField);
Vue.component('VDataTableWithPagination', VDataTableWithPagination);

Vue.filter('numeralSpaces', NumeralSpaces);
Vue.filter('formattedPrice', FormattedPrice);
Vue.use(RolePermissions);

declare global {
  interface Window {
    ethereum?: any;
    web3?: any;
    ga?: any;
    gtag?: any;
    dataLayer?: any;
    theme?: string;
    opera?: string;
  }
}

console.log('VUE_APP_CI_CD_ENV', process.env.VUE_APP_CI_CD_ENV);

const sentryDsn: string =
  typeof process.env.VUE_APP_SENTRY_DSN === 'string'
    ? process.env.VUE_APP_SENTRY_DSN
    : '';

if (!!sentryDsn && !window.location.hostname.includes('translate.goog')) {
  Sentry.init({
    Vue,
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_API_URL, /^\//]
      })
    ],
    environment: process.env.VUE_APP_CI_CD_ENV || 'dev',
    tracesSampleRate: 1.0,
    release: `widget-admin-${process.env.VUE_APP_CI_COMMIT_REF_SLUG}-${process.env.VUE_APP_CI_COMMIT_SHA}`,
    beforeSend: (event) => {
      if (window.location.hostname === 'localhost') {
        return null;
      }

      // ignore 3rd party scripts errors
      if (
        event?.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename ===
        `<anonymous>`
      ) {
        return null;
      }

      return event;
    },
    allowUrls: [/^(https?:\/\/)?([a-zA-Z0-9-]+\.)?trueplay\.io(\/.*)?$/],
    ignoreErrors: [
      // TODO: TP-3371 look at this ignore
      /Request failed with status code .*/,
      /Failed to execute 'removeChild'/,
      /Avoided redundant navigation to current location:/,
      /Redirected when going from ".*?" to ".*?" via a navigation guard/,
      /Navigation cancelled from ".*?" to ".*?" with a new navigation/,
      /Cannot read properties of undefined \(reading 'call'\)/, // error while loading vue module due network problems
      /can't access dead object/, // browser extension error case (Firefox add-ons)
      /^NotAllowedError:*/,
      /Network Error/,
      /Failed to fetch/,
      /getTiles/,
      /timeout of (.*?)ms exceeded/, // network error
      /Loading chunk (.*?) failed/, // network error
      /Loading CSS chunk (.*?) failed/, // network error
      'modules[moduleId] is undefined', // network error
      /User is not found with id : \d+ and operator id : \d+/,
      'gridRect.getBoundingClientRect',
      'The object can not be found here.', // safari error
      /sendObjectBeacon/,
      /webkit-masked-url/,
      'Non-Error promise rejection captured' // https://github.com/getsentry/sentry-javascript/issues/3440
    ]
  });

  Sentry.configureScope(function (scope) {
    scope.setTag('Project', 'admin-ui');
  });
}

declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
  }
}

window.hsConversationsSettings = {
  loadImmediately: false
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
