export const makeSharedRequest = <
  T extends (...args: unknown[]) => Promise<unknown>
>(
  handler: T
): T => {
  const processingMap = new Set<string>();
  let processing: unknown;

  return ((...args: unknown[]) => {
    const key = JSON.stringify(args);
    if (processingMap.has(key)) return processing;

    processingMap.add(key);

    processing = handler(...args).finally(() => {
      processingMap.delete(key);
    });

    return processing as ReturnType<T>;
  }) as T;
};
