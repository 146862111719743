import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type {
  PromoCampaignListResponse,
  PromoCampainPartisipanstSearchParams,
  PromoCampaignUpdateData,
  PromoCampaignData,
  PromoCampaignParticipant,
  PromoParticipantsListResponse,
  PromoCampaignDataRequest,
  PromoParticipantCashbackResponse,
  PromoParticipantTotalParams,
  PromoParticipantCashbackTotalResponse,
  IPromoGameBlackListParams,
  IPromoGameBlackListResponse
} from './schema';
import type { AxiosResponse } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { AxiosError } from 'axios';
import { PromoCampaignByUser } from './schema';

/**
 * Get all campaigns.
 * @return Promise with promoCampaigns data.
 * @param requestData Campaings search params.
 */
export async function getPromoCampaigns(
  requestData: PromoCampaignDataRequest
): Promise<PromoCampaignListResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign`), {
      params: {
        ...requestData
      }
    })
    .then(({ data }: AxiosResponse): PromoCampaignListResponse => data);
}

/**
 * Get all active campaigns.
 * @return Promise with promoCampaigns data.
 */
export async function getActiveCampaigns(): Promise<PromoCampaignData[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/active`))
    .then(({ data }: AxiosResponse): PromoCampaignData[] => data);
}

/**
 * Get promo campaign participants by campaign id.
 * @return Promise with participants data.
 */
export async function getPromoCampaignsParticipantsReward(
  requestData: PromoParticipantTotalParams,
  id: number
): Promise<number> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}/reward`), {
      params: {
        ...requestData
      },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): number => data.amount);
}

/**
 * Get promo campaign participants reward by date.
 * @return Promise with participants reward data.
 * @param requestData Participants search params.
 * @param id campaign id.
 */
export async function getPromoCampaignsParticipants(
  requestData: PromoCampainPartisipanstSearchParams,
  id: number
): Promise<PromoParticipantsListResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}/participants`), {
      params: {
        ...requestData
      },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): PromoParticipantsListResponse => data);
}

/**
 * Get promo campaign cashback participants by date.
 * @return Promise with participants reward data.
 * @param requestData Participants search params.
 * @param id campaign id.
 */
export async function getPromoCampaignsCashbackUsers(
  requestData: PromoCampainPartisipanstSearchParams,
  id: number
): Promise<PromoParticipantCashbackResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}/cashback_users`), {
      params: requestData,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): PromoParticipantCashbackResponse => data);
}

export async function getPromoCampaignsCashbackTotal(
  requestData: PromoParticipantTotalParams,
  id: number
): Promise<PromoParticipantCashbackTotalResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}/cashback_total`), {
      params: requestData,
      cacheTime: 0
    })
    .then(
      ({ data }: AxiosResponse): PromoParticipantCashbackTotalResponse => data
    );
}

export async function getPromoCampaignsCashbackUsersReport(
  requestData: PromoCampainPartisipanstSearchParams,
  id: number
): Promise<string> {
  return http
    .get(
      buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}/cashback_users/csv`),
      {
        params: requestData
      }
    )
    .then(({ data }: AxiosResponse): string => data);
}

/**
 * Delete campaign by id.
 * @param id Campaings id.
 */
export async function deletePromoCampaignById(
  id: number
): Promise<PromoCampaignData> {
  return http.delete(buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}`));
}

/**
 * Update campaign status.
 * @return Promise with promoCampaign data.
 * @param updateData Campaing update data.
 */
export async function updatePromoCampaignStatus({
  id,
  status
}: PromoCampaignUpdateData): Promise<unknown> {
  return http.patch(
    buildUrl(`/api/v1/{operatorId}/promo-campaign/${id}`),
    null,
    {
      params: {
        status
      }
    }
  );
}

/**
 * Create campaign.
 * @return Promise with сampaign data.
 * @param campaignData Campaing data.
 */

export async function createPromoCampaign(
  campaignData: PromoCampaignData
): Promise<PromoCampaignData> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/promo-campaign`), campaignData)
    .then(({ data }: AxiosResponse): PromoCampaignData => data);
}

/**
 * Update campaign.
 * @return Promise with сampaign data.
 * @param campaignData Campaing data.
 * @param campaignId
 */

export async function updatePromoCampaign(
  campaignData: PromoCampaignData,
  campaignId: number
): Promise<PromoCampaignData> {
  return http
    .put(
      buildUrl(`/api/v1/{operatorId}/promo-campaign/${campaignId}`),
      campaignData
    )
    .then(({ data }: AxiosResponse): PromoCampaignData => data);
}

/**
 * Assign promo campaign rewards by user.
 * @return Promise with user data.
 * @param userData user data.
 */

export async function giveReward(
  campaignId: number,
  userId: string
): Promise<PromoCampaignParticipant> {
  return http
    .post(
      buildUrl(
        `/api/v1/{operatorId}/promo-campaign/${campaignId}/user/${userId}`
      )
    )
    .then(({ data }: AxiosResponse): PromoCampaignParticipant => data);
}

/**
 * Get Promo Campaign candidates.
 * @return Promise with Promo Campaign  candidates.
 */
export async function getPromoCampaignCandidates(
  campaignId: number
): Promise<string[]> {
  return http
    .get(
      buildUrl(`/api/v1/{operatorId}/promo-campaign/${campaignId}/candidate`),
      {
        cacheTime: 0
      }
    )
    .then(({ data }: AxiosResponse): string[] => data);
}

/**
 * Add Promo Campaign candidates.
 */
export async function addPromoCampaignCandidates(
  campaignId: number,
  usersData: string[]
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/promo-campaign/${campaignId}/candidate`),
    usersData
  );
}

/**
 * Get promo campaign by user id
 * @param id user id
 * @return promo campaign by user id
 */
export async function getUserPromoCampaign(
  id: number
): Promise<PromoCampaignByUser[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/user/${id}`))
    .then(
      ({ data }: AxiosResponse<PromoCampaignByUser[]>): PromoCampaignByUser[] =>
        data
    );
}

/**
 * Remove promo campaign rewards by user.
 * @return Promise
 * @param campaignId
 * @param userId
 */

export async function removeReward(
  campaignId: number,
  userId: string
): Promise<any> {
  return http
    .delete(
      buildUrl(
        `/api/v1/{operatorId}/promo-campaign/${campaignId}/user/${userId}`
      )
    )
    .catch((error: AxiosError) => {
      errorToastMessage(error);
      throw error;
    });
}

/**
 * Remove promo campaign rewards by user.
 * @return Promise
 * @param campaignId
 * @param userId
 */

export const removeUserFromCampaign = (
  campaignId: number,
  userId: string
): Promise<any> => {
  return http.delete(
    buildUrl(
      `/api/v1/{operatorId}/promo-campaign/${campaignId}/candidate/${userId}`
    )
  );
};

export async function includedGamesPromoCampaign(
  campaignId: string,
  gameIds: number[]
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/promo-campaign/${campaignId}/game/include`),
    null,
    {
      params: { gameIds },
      paramsSerializer: {
        indexes: null
      }
    }
  );
}

export async function excludedGamesPromoCampaign(
  campaignId: string,
  gameIds: number[]
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/promo-campaign/${campaignId}/game/exclude`),
    null,
    {
      params: { gameIds },
      paramsSerializer: {
        indexes: null
      }
    }
  );
}

export async function getGamesBlacklistPromoCampaign(
  campaignId: string,
  params: IPromoGameBlackListParams
): Promise<IPromoGameBlackListResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/promo-campaign/${campaignId}/game`), {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse<IPromoGameBlackListResponse>) => data);
}
