import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  AccountOperator,
  AccountTokenName,
  AccountUser,
  AccountUserUpdatePayload,
  CompanyInfo,
  DeleteRequestData,
  EnableWidgetPayload,
  IOperatorEventsParams,
  OperatorLanguages,
  OperatorUserCreatePayload,
  TAccountStorage,
  TOperatorEventsList,
  CreateAccountOperatorParams
} from './schema';

/**
 * Get widget languages.
 */
export async function getOperatorLanguages(): Promise<OperatorLanguages> {
  return http
    .get(buildUrl(`/api/v2/operator/{operatorId}/languages`))
    .then(({ data }: AxiosResponse): OperatorLanguages => data);
}

/**
 * Set default widget language.
 */
export async function updateOperatorDefaultLanguage(
  language: string
): Promise<OperatorLanguages> {
  return http
    .put(
      buildUrl(`/api/v1/operator/{operatorId}/default-language`),
      {},
      {
        params: { language }
      }
    )
    .then(({ data }: AxiosResponse): OperatorLanguages => data);
}

/**
 * Set the list of available widget languages
 */
export async function updateOperatorWidgetLanguagesList(
  data: string[]
): Promise<string[]> {
  return http
    .post(buildUrl(`/api/v1/operator/{operatorId}/languages`), data)
    .then(({ data }: AxiosResponse): string[] => data);
}

/**
 * Get account operators users.
 */
export async function getAccountUsers(): Promise<AccountUser[]> {
  return http
    .get(buildUrl('/api/v1/account/operator/{operatorId}/user'))
    .then(({ data }: AxiosResponse): AccountUser[] => data);
}

/**
 * Create account operator user.
 */
export async function createAccountUser(
  data: OperatorUserCreatePayload
): Promise<AccountUser> {
  return http
    .post('/api/v1/account/user', data)
    .then(({ data }: AxiosResponse): AccountUser => data);
}

/**
 * Update account operator user role.
 */
export async function updateAccountUser(
  data: AccountUserUpdatePayload
): Promise<unknown> {
  const { role, operators } = data;
  return http
    .post(`/api/v1/account/user/${data.userId}`, { role, operators })
    .then(({ data }: AxiosResponse): AccountUser => data);
}

/**
 * Delete account operator user.
 */
export async function deleteAccountUser({
  userId
}: DeleteRequestData): Promise<any> {
  return http.delete(`api/v1/account/user/${userId}`);
}

/**
 * Update operator default currency.
 */
export async function updateOperatorCurrency(
  currency: string
): Promise<unknown> {
  return http.put(buildUrl(`/api/v1/operator/{operatorId}/default-currency`), {
    ticker: currency
  });
}

/**
 * Enable/disable widget.
 */
export async function updateOperatorWidgetStatus({
  operatorId,
  enabled
}: EnableWidgetPayload): Promise<unknown> {
  return http.post(`/api/v1/operator/${operatorId}/widget`, null, {
    params: { enabled }
  });
}

/**
 * Get operator company info
 */

export async function getCompanyInfo(): Promise<CompanyInfo> {
  return http
    .get(buildUrl(`/api/v1/operator/{operatorId}/company-info`), {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<CompanyInfo>): CompanyInfo => data);
}

/**
 * Update operator company info
 */

export async function saveCompanyInfo(
  payload: CompanyInfo
): Promise<CompanyInfo> {
  return http
    .post(buildUrl(`/api/v1/operator/{operatorId}/company-info`), payload)
    .then(({ data }: AxiosResponse<CompanyInfo>): CompanyInfo => data);
}

/**
 * Get account operators list.
 */

export async function getAccountOperators(): Promise<AccountOperator[]> {
  return http
    .get(buildUrl(`/api/v1/operator/admin`))
    .then(
      ({ data }: AxiosResponse<AccountOperator[]>): AccountOperator[] => data
    );
}

export async function checkTokenName(name: string): Promise<boolean> {
  return http
    .get(buildUrl(`/api/v1/operator-onboarding/currency/name/${name}`))
    .then(({ data }: AxiosResponse<AccountTokenName>): boolean => data.valid);
}

export const getAccountStorage = (): Promise<TAccountStorage> =>
  http
    .get('/api/v1/account/ui-settings')
    .then(({ data }: AxiosResponse): TAccountStorage => data);

export const updateAccountStorage = (storage: TAccountStorage): Promise<void> =>
  http.put('/api/v1/account/ui-settings', storage);

/**
 * Get operator events list.
 */
export async function getOperatorEvents(): Promise<TOperatorEventsList> {
  return http
    .get(buildUrl(`/api/v1/operator-events/{operatorId}`))
    .then(({ data }: AxiosResponse): TOperatorEventsList => data);
}

/**
 * Update operator event type status.
 */
export async function updateOperatorEvents(
  params: IOperatorEventsParams
): Promise<TOperatorEventsList> {
  const { type, active } = params;

  return http
    .post(
      buildUrl(`/api/v1/operator-events/{operatorId}`),
      {},
      { params: { type, active } }
    )
    .then(({ data }: AxiosResponse): TOperatorEventsList => data);
}

export async function createAccountOperator(
  accountId: number,
  params: CreateAccountOperatorParams
): Promise<AccountOperator[]> {
  return http
    .post(`/api/v1/super-admin/account/${accountId}/operator`, params)
    .then(
      ({ data }: AxiosResponse<AccountOperator[]>): AccountOperator[] => data
    );
}
