import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import {
  ApproveWithdrawRequestPayload,
  CommentWithdrawRequestPayload,
  GetWithdrawRequestByIdPayload,
  GetWithdrawRequestsPayload,
  GetWithdrawRequestsResponse,
  WithdrawRequestItem
} from '@/api/schema';

/**
 * Get list of withdrawal requests
 * @return Promise.
 */
export async function getWithdrawRequests(
  params: GetWithdrawRequestsPayload
): Promise<GetWithdrawRequestsResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/custody/withdraw`), { params })
    .then(({ data }: AxiosResponse): GetWithdrawRequestsResponse => data);
}

/**
 * Change withdrawal request data by its ID
 * @return Promise.
 */
export async function getWithdrawRequestById(
  payload: GetWithdrawRequestByIdPayload
): Promise<WithdrawRequestItem> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/custody/withdraw/${payload.id}`))
    .then(({ data }: AxiosResponse): WithdrawRequestItem => data);
}

/**
 * Approve or decline withdrawal request
 * @return Promise.
 */
export async function approveWithdrawRequest(
  payload: ApproveWithdrawRequestPayload
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/custody/withdraw/${payload.id}/approve`),
    payload
  );
}

/**
 * Add comment to withdrawal request
 * @return Promise.
 */
export async function commentWithdrawRequest(
  payload: CommentWithdrawRequestPayload
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/custody/withdraw/${payload.id}/comment`),
    payload
  );
}
