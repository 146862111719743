import Decimal from 'decimal.js-light';
const SEPARATOR = ' ';

const separateIntNumber = (value: string | number): string => {
  if (!value) {
    return '';
  }

  if (typeof value === 'number') {
    value = String(value);
  }

  if (value.length <= 3) {
    return value;
  }

  return reverse(
    reverse(value).replace(/(\d{3})(?!-)/g, `$1${SEPARATOR}`)
  ).trim();
};

export const reverse = (s: string): string => {
  return s.split('').reverse().join('');
};

export const separate = (value: string | number): string => {
  if (!value) {
    return '';
  }

  if (typeof value === 'number') {
    value = String(value);
  }

  const [intPart, decPart] = value.split('.');

  if (!decPart) {
    return separateIntNumber(intPart);
  }

  return `${separateIntNumber(intPart)}.${decPart}`;
};

export const isInteger = (value: string): boolean => {
  return Number.isInteger(parseInt(value));
};

export const fromPercents = (value: number, fractionDigits = 6): number => {
  return +(value / 100).toFixed(fractionDigits);
};

export const toPercents = (value: number, fractionDigits = 4): number => {
  return +(value * 100 || 0).toFixed(fractionDigits);
};

export const demicalSum = (number1: number, number2: number): number => {
  return new Decimal(number1 ?? 0).plus(number2 ?? 0).toNumber();
};
