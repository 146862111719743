

















import { pluralize } from '@/helpers/pluralize';
export default {
  name: 'VDataTableSelectDialog',
  props: {
    selected: {
      type: Array,
      default: (): any => []
    },
    selectLabels: {
      type: Array,
      default: (): string[] => []
    }
  },

  data(): any {
    return {
      open: false
    };
  },

  computed: {
    selectedCount(): number {
      return this.selected.length;
    },
    selectedLabel(): string {
      const label = pluralize(this.selectedCount, this.selectLabels);

      return `${this.selectedCount} ${label} selected`;
    }
  },

  watch: {
    selectedCount(count: number): void {
      this.open = !!count;
    }
  }
};
