import { cond, stubTrue } from 'lodash';

export const pluralize = (n: number, [one, few, many]: string[]): string => {
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  return cond([
    [() => lastTwoDigits >= 11 && lastTwoDigits <= 14, () => many],
    [() => lastDigit === 1, () => one],
    [() => lastDigit >= 2 && lastDigit <= 4, () => few],
    [stubTrue, () => many]
  ])();
};
