import SecureLS from 'secure-ls';
import { http } from './Connect';

import type { AuthResponse } from '@/api/schema';
import type { AxiosResponse } from 'axios';
import { ERole } from '@/api/schema';

const ls = new SecureLS();

export enum ESignUpType {
  DEFAULT = 'DEFAULT',
  SALE = 'SALE_REGISTRATION'
}

export type TSignUpPayload = {
  email: string;
  password?: string;
  businessType: string;
  brandName?: string;
  tokenSymbol?: string;
  activeUsers?: string;
  ggr?: string;
  requestType: ESignUpType;
  dealId?: string;
  tokenNumber: number;
  tokenPrice: number;
  tokenName: string;
  operatorName: string;
  managerId?: number;
  copyStakeAccount?: boolean;
};

export type TInvitePayload = {
  login?: string;
  operators: string | number[];
  role: ERole;
};

export type TInviteRegisterPayload = {
  token: string;
  email: string;
  password: string;
};

export type TInviteGameProviderRegisterPayload = {
  token: string;
  password: string;
};

export type TInviteLinkResponse = { role: ERole; link: string }[];

/**
 * Superadmin authentication.
 * @return Promise with token.
 */
export async function LoginSuperAdmin(
  email: string,
  password: string
): Promise<AuthResponse> {
  ls.remove('tokenKey');
  http.removeToken();

  return http
    .post('/api/v1/security/admin/signin', {
      email,
      password
    })
    .then(({ data }: AxiosResponse): AuthResponse => data);
}

/**
 * Game Provider Admin authentication.
 * @return Promise with token.
 */
export async function LoginGameProviderAdmin(
  email: string,
  password: string
): Promise<AuthResponse> {
  ls.remove('tokenKey');
  http.removeToken();

  return http
    .post('/api/v1/security/game-provider/signin', {
      email,
      password
    })
    .then(({ data }: AxiosResponse): AuthResponse => data);
}

/**
 * Admin and Account authentication.
 * @return Promise with token.
 */
export async function LoginAdmin(
  email: string,
  password: string
): Promise<AuthResponse> {
  ls.remove('tokenKey');
  ls.remove('lastActiveOperator');
  http.removeToken();

  return http
    .post('/api/v1/security/operator/signin', {
      email,
      password
    })
    .then(({ data }: AxiosResponse): AuthResponse => data);
}

/**
 * User registration.
 */
export async function RegisterUser(payload: TSignUpPayload): Promise<unknown> {
  return http.post('/api/v2/security/signup', payload);
}

type TRegisterCheckResponse = {
  valid: boolean;
};

export const restorePassword = (
  password: string,
  token: string
): Promise<any> =>
  http.post(
    'api/v2/security/change-password',
    {
      password
    },
    {
      headers: {
        'X-AUTH-TOKEN': `Bearer ${token}`
      }
    }
  );

export const requestRestorePasswordEmail = (email: string): Promise<any> =>
  http.post(
    '/api/v2/security/reset-password',
    { email },
    { params: { email } }
  );

export const requestAccountEmailVerifyLetter = (): Promise<any> =>
  http.get('/api/v3/security/signup/request/resend');

export const requestEmailVerifyLetter = (): Promise<any> =>
  http.get('/api/v1/security/admin/signup/request/resend');

export const checkRegisterData =
  (type: string) =>
  (value: string): Promise<boolean> =>
    http
      .get(`/api/v2/security/verify/${type}`, { params: { value } })
      .then(
        ({ data }: AxiosResponse<TRegisterCheckResponse>): boolean => data.valid
      );

export const checkEmail = checkRegisterData('email');
export const checkBrand = checkRegisterData('brand');
export const checkToken = checkRegisterData('currency');

export const signUpRequest = (payload: TSignUpPayload): Promise<AuthResponse> =>
  http
    .post('/api/v3/security/signup/request', payload)
    .then(({ data }: AxiosResponse): AuthResponse => data);

export const getRegistrationData = (token: string): Promise<any> =>
  http
    .get('/api/v3/security/signup/request/form', {
      params: {
        token
      }
    })
    .then(({ data }: AxiosResponse): any => data);

export const signUpVerify = (
  token: string,
  emailConfirm = false,
  password?: string
): Promise<AuthResponse> =>
  http
    .post(
      `/api/v3/security/signup/verify?token=${token}&e-mail-confirm=${emailConfirm}`,
      password,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    )
    .then(({ data }: AxiosResponse): AuthResponse => data);

export const sendInviteByEmail = (payload: TInvitePayload): Promise<any> =>
  http.post('/api/v1/security/admin/signup/request', payload);

export const getInviteLinks = (
  payload: Pick<TInvitePayload, 'operators'>
): Promise<TInviteLinkResponse> =>
  http
    .post('/api/v1/security/admin/signup', payload)
    .then(({ data }: AxiosResponse): TInviteLinkResponse => data);

export const registerUserFromInvite = (
  payload: TInviteRegisterPayload
): Promise<AuthResponse> =>
  http
    .post(
      `/api/v1/security/admin/signup/verify?token=${payload.token}`,
      payload
    )
    .then(({ data }: AxiosResponse): AuthResponse => data);

export const registerGameProviderFromInvite = (
  payload: TInviteGameProviderRegisterPayload
): Promise<AuthResponse> =>
  http
    .post(`/api/v3/security/signup/game-provider/verify`, payload)
    .then(({ data }: AxiosResponse): AuthResponse => data);
