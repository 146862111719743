export enum WITHDRAW_REQUEST_STATUSES {
  SUCCESS = 'SUCCESS',
  CREATED = 'CREATED',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED'
}

export enum WITHDRAW_DECLINE_REASONS {
  AML = 'AML',
  USER_DECLINED = 'USER_DECLINED',
  OTHER = 'OTHER'
}

export const WithdrawRequestStatusesArray = [
  { value: WITHDRAW_REQUEST_STATUSES.SUCCESS, text: 'Success' },
  { value: WITHDRAW_REQUEST_STATUSES.CREATED, text: 'Created' },
  { value: WITHDRAW_REQUEST_STATUSES.DECLINED, text: 'Declined' },
  { value: WITHDRAW_REQUEST_STATUSES.APPROVED, text: 'Approved' },
  { value: WITHDRAW_REQUEST_STATUSES.PROCESSING, text: 'Processing' },
  { value: WITHDRAW_REQUEST_STATUSES.FAILED, text: 'Failed' }
];

export const WithdrawDeclineReasonsArray = [
  { value: WITHDRAW_DECLINE_REASONS.AML, text: 'AML Policy' },
  {
    value: WITHDRAW_DECLINE_REASONS.USER_DECLINED,
    text: 'User asked to decline withdrawal request'
  },
  { value: WITHDRAW_DECLINE_REASONS.OTHER, text: 'Other' }
];

export enum WITHDRAW_REQUEST_TABS {
  PENDING = 'pending',
  ALL = 'all'
}

export const WithdrawRequestTabsWithLabel = [
  { id: WITHDRAW_REQUEST_TABS.PENDING, text: 'Pending' },
  { id: WITHDRAW_REQUEST_TABS.ALL, text: 'All Requests' }
];
