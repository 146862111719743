import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type {
  TokenPriceData,
  FrozenTokensData,
  TokenInfoData,
  FixedPriceTokenEmissionData
} from './schema';
import type { AxiosResponse } from 'axios';

/**
 * Get total frozen tokens amount.
 * @return Promise with frozen tokens amount.
 */
export async function getFrozenTokensData(): Promise<FrozenTokensData> {
  return http
    .get(buildUrl(`/api/v1/staking?operatorId={operatorId}`))
    .then(({ data }: AxiosResponse): FrozenTokensData => data);
}

/**
 * Get current token price data.
 * @return Promise with current token price data.
 */
export async function getTokenPriceData(): Promise<TokenPriceData> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/info/token-price`))
    .then(({ data }: AxiosResponse): TokenPriceData => data);
}

/**
 * Get token info.
 * @return Promise with current token info.
 */
export async function getTokenInfoData(): Promise<TokenInfoData[]> {
  return http
    .get(buildUrl(`/api/v1/info/token?operatorId={operatorId}`))
    .then(({ data }: AxiosResponse): TokenInfoData[] => data);
}

/**
 * Get fixed price token emission info.
 * @return Promise with current token emission info.
 */
export async function getFixedPriceTokenEmissionData(): Promise<FixedPriceTokenEmissionData> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/balance/emission-paid`))
    .then(({ data }: AxiosResponse): FixedPriceTokenEmissionData => data);
}
