import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  GameTypesResponse,
  GameType,
  GameTypeChangeRequest,
  GameTypeCreateRequest,
  GameListItemResponse,
  GameTypesDropdownItem
} from './schema';

/**
 * Get game types.
 * @return Promise with game types.
 */
export async function getGameTypes(size: number): Promise<GameTypesResponse> {
  return http
    .get(buildUrl(`/api/v1/games/types?operatorId={operatorId}&size=${size}`))
    .then(({ data }: AxiosResponse): GameTypesResponse => data);
}

/**
 * Change game types.
 * @return Promise with changed game type.
 */
export async function changeGameType(
  changeData: GameTypeChangeRequest
): Promise<GameType> {
  const { id } = changeData;
  return http
    .put(buildUrl(`/api/v1/{operatorId}/games/types/${id}`), { ...changeData })
    .then(({ data }: AxiosResponse): GameType => data);
}

export async function createGameType(
  formData: GameTypeCreateRequest
): Promise<GameType> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/game/game-type`), formData)
    .then(({ data }: AxiosResponse): GameType => data);
}

export async function deleteGameType(gameTypeId: number): Promise<void> {
  return http.delete(
    buildUrl(`/api/v1/{operatorId}/games/types/${gameTypeId}`)
  );
}

export async function getGameByGameType(
  type: string,
  params?: any[]
): Promise<GameListItemResponse[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/game/all?gameTypeName=${type}`), {
      params
    })
    .then(({ data }: AxiosResponse): GameListItemResponse[] => data);
}

export async function getGameTypesList(): Promise<GameTypesDropdownItem[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/game/dropdown`))
    .then(({ data }: AxiosResponse): GameTypesDropdownItem[] => data);
}

export async function updateGame(
  gameId: number,
  gameTypeId: number
): Promise<number> {
  return http
    .patch(buildUrl(`/api/v1/{operatorId}/game/${gameId}`), {
      gameTypeId
    })
    .then(({ data }: AxiosResponse): number => data);
}
