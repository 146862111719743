import getSymbolFromCurrency from 'currency-symbol-map';
import Decimal from 'decimal.js-light';
import store from '@/store/index';

export const getCurrencySymbol = (ticker: string = 'USD'): string => {
  switch (ticker) {
    case 'CAD':
      return 'C$';
    case 'CLP':
      return 'CLP$';
    default:
      return getSymbolFromCurrency(ticker);
  }
};

export const usdToOperatorCurrency = (usdAmount: number): number => {
  const usd = new Decimal(isNaN(usdAmount) ? 0 : usdAmount);
  const priceInUsd = store.state.TokensInfo?.tokenPrice?.priceInUsd || 1;

  return usd.times(priceInUsd).toNumber();
};

export const usdToUsdt = (usdAmount: number): number => {
  if (!usdAmount) return 0;

  const usd = new Decimal(usdAmount);
  const priceInUsdt =
    store.state.TokensInfo?.tokenPrice?.usdToUsdtExchangeRate || 1;

  return usd.times(priceInUsdt).toNumber();
};

export const operatorCurrencyToUsdt = (amount: number): number => {
  const currencyAmount = new Decimal(isNaN(amount) ? 0 : amount);
  const priceInUsd = store.state.TokensInfo?.tokenPrice?.priceInUsd || 1;

  return usdToUsdt(currencyAmount.div(priceInUsd).toNumber());
};

export const tokensToCurrency = (tokensAmount: number): number => {
  if (!tokensAmount) return 0;

  const tokens = new Decimal(tokensAmount);
  const priceInCurrency = store.state.TokensInfo?.tokenPrice?.price || 0;

  return tokens.times(priceInCurrency).toNumber();
};
