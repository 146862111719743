import NumeralSpaces from '@/filters/numeral-spaces';

export default (
  value: number | string | null,
  symbol: string = '$',
  numeralFormat: string = '0,0.[00]',
  undefinedText: string = '-'
): string => {
  const formattedValue = NumeralSpaces(value, numeralFormat);

  return value ? formattedValue + ' ' + symbol : undefinedText;
};
