import Vue from 'vue';
import type { AxiosError } from 'axios';

export const errorToastMessage = (error?: AxiosError, params = {}): void => {
  const errorData = error?.response?.data;

  const msg =
    (errorData as any)?.message ||
    error?.message ||
    'Something went wrong! Please try again!';

  Vue.prototype.$toast.error(msg, params);
};
