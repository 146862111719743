










import Vue from 'vue';
import MobileLockView from '@/views/MobileLockView.vue';
import { http } from '@/api/Connect';
import { mapActions, mapState } from 'vuex';
import type { AxiosError } from 'axios';
import { getMsToTokenExpire } from '@/helpers/tokenHelpers';
import OperatorBalanceAddFundsDialog from '@/views/OperatorBalanceView/OperatorBalanceAddFundsDialog.vue';

const GitlabLink = (): Promise<any> =>
  import(/* webpackMode: "lazy" */ '@/components/links/GitlabLink.vue');

export default Vue.extend({
  name: 'App',
  components: {
    OperatorBalanceAddFundsDialog,
    GitlabLink,
    MobileLockView
  },
  watch: {
    ...(process.env.NODE_ENV !== 'prod'
      ? {
          token: {
            handler(val: string): void {
              if (val) {
                if (!window?.HubSpotConversations?.widget) return;

                const status = window?.HubSpotConversations?.widget?.status();

                if (status?.loaded) {
                  window.HubSpotConversations.widget.refresh();
                } else {
                  window.HubSpotConversations.widget.load();
                }
              } else {
                window?.HubSpotConversations?.widget?.remove({
                  resetWidget: true
                });
              }
            },
            immediate: true
          }
        }
      : {}),
    role: {
      handler(role: string): void {
        this.$role.setPermissions(role);
      },
      immediate: true
    }
  },
  created: function () {
    const msToExpire = getMsToTokenExpire(this.token);

    if (msToExpire > 0) {
      setTimeout(() => {
        this.logoutAction();
      }, msToExpire);
    }

    http.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError<any>) => {
        if (error.response?.status === 401) {
          this.logoutAction();
        }
        // reject with http error response
        return Promise.reject(error);
      }
    );
  },
  computed: {
    ...mapState('app', ['showLoader']),
    ...mapState('ClientsInfo', ['clientsInfo']),
    ...mapState('Auth', ['role', 'token']),
    ...mapState('app', ['isSuperAdmin']),
    isMobile(): boolean {
      const isTest = localStorage.getItem('test');

      return this.$vuetify.breakpoint.smAndDown && !isTest;
    },
    isDevEnv(): boolean {
      return process.env.NODE_ENV === 'development';
    },
    isShowGitlabLink(): boolean {
      return this.isDevEnv && !this.showLoader;
    },
    isDisabledAppClass(): boolean {
      return (
        this.clientsInfo.widgetEnabled === false &&
        !this.$role.is('account') &&
        !this.isSuperAdmin
      );
    }
  },
  methods: {
    ...mapActions('Auth', ['logout']),
    logoutAction(): void {
      this.logout()
        .then(() => this.$router.push('/login'))
        .catch((err) => {
          // silent navigation error
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
    }
  }
});
