import retry from 'axios-retry';
import axios, { AxiosInstance } from 'axios';
import SecureLS from 'secure-ls';
import { ClientCache } from '@/helpers/ClientCache';
const ls = new SecureLS();
const token = ls.get('tokenKey');

/**
 * Http for connection to the API.
 */
export const http = new ClientCache<AxiosInstance>(
  axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'X-AUTH-TOKEN': `Bearer ${token}`
    }
  })
);

// Configure the retry plugin in case of network error.
retry(http.client, {
  retryDelay: (count: number): number => count * 1000
});
