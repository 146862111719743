import { DateTime } from 'luxon';
import { EStreamerTransactionType, DateRangeParams } from '@/api/schema';

export const STATS_NAME = {
  [EStreamerTransactionType.BET_COPIED]: 'BET Copied',
  [EStreamerTransactionType.WIN_COPIED]: 'WIN Copied',
  [EStreamerTransactionType.ROLLBACK_BET_COPIED]: 'Rollback Bet Copied',
  [EStreamerTransactionType.ROLLBACK_WIN_COPIED]: 'Rollback Win Copied'
};

export const VALUE_PLACEHOLDER = '-';
export const BASE_CURRENCY = 'USDT';

export const END_DATE = DateTime.now().minus({ hour: 1 });
export const START_DATE = DateTime.fromJSDate(new Date('2023-10-01'));
export const FORMAT_DATE = 'yyyy-LL-dd';

export type TStreamFormDates = { [key: string]: string };

export const defaultDates: DateRangeParams = {
  from: START_DATE.toFormat(FORMAT_DATE),
  to: END_DATE.toFormat(FORMAT_DATE)
};
export const MIN_DATE = START_DATE.toFormat(FORMAT_DATE);

export const addThumbnailPrefix = (url: string): string => {
  const parts = url.split('/');
  let filename = parts.pop();

  filename = 'thumbnail_' + filename;

  parts.push(filename);

  return parts.join('/');
};
