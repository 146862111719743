import millify from 'millify';
import { separate } from '@/helpers/decimalsHelpers';

export const roundNumber = (
  number: number | string,
  maxLength: number = 7,
  precision: number = 2
): string => {
  if (!number) {
    return '0';
  }

  if (typeof number === 'string') {
    number = Number(number);
  }

  if (precision > maxLength) {
    precision = maxLength;
  }

  const smallest = precision > 1 ? +`0.${'0'.repeat(precision - 1)}1` : '0.1';

  if (Math.abs(+number) < smallest) {
    return `< ${smallest}`;
  }

  // no scientific notation, format to 0.[00]
  const strNumber = number.toLocaleString('en-US-fullwide', {
    useGrouping: false,
    maximumFractionDigits: precision
  });

  const [intPart, decPart = ''] = strNumber.split('.');

  if (intPart.length > maxLength) {
    return millify(number, {
      space: false,
      precision: 1
    });
  }

  if (decPart) {
    return `${separate(intPart)}.${decPart}`;
  }

  return separate(intPart);
};
