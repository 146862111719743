import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type { ProviderStatus } from './schema';

/**
 * Get game providers mining statuses.
 * @return Promise with game providers mining statuses.
 */
export async function getGameProvidersMiningData(): Promise<ProviderStatus[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/game-provider/mining/status`))
    .then(({ data }: AxiosResponse): ProviderStatus[] => data.gameProviders);
}

/**
 * Stop game-provider mining.
 */
export async function stopGameProviderMining(id: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/game-provider/${id}/mining/stop`)
  );
}

/**
 * Start game-provider mining.
 */
export async function startGameProviderMining(id: number): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/{operatorId}/game-provider/${id}/mining/start`)
  );
}
