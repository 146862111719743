import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type { OperatorBalance } from './schema';

/**
 * Save operator balance settings
 */
export async function saveOperatorBalance(
  payload: OperatorBalance
): Promise<OperatorBalance> {
  return http
    .put(buildUrl(`/api/v1/{operatorId}/balance`), payload)
    .then(({ data }: AxiosResponse): OperatorBalance => data);
}
