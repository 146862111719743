import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type { MiningStatusResponse } from './schema';

/**
 * Get mining status.
 * @return Promise with mining status.
 */
export async function getMiningStatus(): Promise<MiningStatusResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/mining/status`))
    .then(({ data }: AxiosResponse): MiningStatusResponse => data);
}

/**
 * Stop mining.
 */
export async function stopMining(): Promise<any> {
  return http.post(buildUrl(`/api/v1/{operatorId}/mining/stop`));
}
/**
 * Start mining.
 */
export async function startMining(): Promise<any> {
  return http.post(buildUrl(`/api/v1/{operatorId}/mining/start`));
}
