import axios, {
  Axios,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

interface ICacheEntry<T> {
  data: T | any;
  timestamp: number;
  cacheTime: number; // how long cache live
  cacheTimeout?: number; // after what amount of time cache entry must be deleted
}

interface ICachedRequestConfig
  extends AxiosRequestConfig,
    Partial<Pick<ICacheEntry<any>, 'cacheTime' | 'cacheTimeout'>> {}

const DEFAULT_CACHE_TIME = 5 * 1000; // 5 seconds
const TOKEN_KEY = 'X-AUTH-TOKEN';

export class ClientCache<T> {
  private readonly _cacheTime: number;
  private _cache: Map<string, ICacheEntry<T>>;
  public client: AxiosInstance;

  constructor(
    axiosInstance: AxiosInstance = axios.create(),
    cacheTime: number = DEFAULT_CACHE_TIME
  ) {
    this._cacheTime = cacheTime;
    this._cache = new Map();
    this.client = axiosInstance;
  }

  public setToken(token: string): void {
    this.client.defaults.headers[TOKEN_KEY] = 'Bearer ' + token;
  }

  public removeToken(): void {
    delete this.client.defaults.headers[TOKEN_KEY];
  }

  public get<T>(
    url: string,
    config?: ICachedRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'get' });
  }

  public head<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.head<T>(url, config);
  }

  public options<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.options<T>(url, config);
  }

  get patch(): Axios['patch'] {
    return this.client.patch;
  }

  get delete(): Axios['delete'] {
    return this.client.delete;
  }

  get put(): Axios['put'] {
    return this.client.put;
  }

  public post<T = any>(
    url: string,
    data?: unknown,
    config?: ICachedRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, data, method: 'post' });
  }

  private request<T>(config: ICachedRequestConfig): Promise<AxiosResponse<T>> {
    const cacheKey = ClientCache._getCacheKey(config);
    const cachedEntry = this._cache.get(cacheKey);

    if (cachedEntry) {
      const cacheEntry = cachedEntry;
      if (
        ClientCache._isCacheValid(cacheEntry.timestamp, cacheEntry.cacheTime)
      ) {
        return Promise.resolve(cacheEntry.data);
      }
    }
    const {
      cacheTime = this._cacheTime,
      cacheTimeout = this._cacheTime,
      ...axiosConfig
    } = {
      ...config
    };

    const method = config.method?.toLowerCase() || '';
    const needCache = cacheTime !== 0;

    if (ClientCache._isCacheableMethod(method) && needCache) {
      return this.client
        .request<T>(axiosConfig)
        .then((response: AxiosResponse<T>) => {
          const newCacheEntry: ICacheEntry<T> = {
            data: response,
            timestamp: Date.now(),
            cacheTime,
            cacheTimeout
          };
          this._cache.set(cacheKey, newCacheEntry);

          if (cacheTimeout) {
            setTimeout(() => {
              this._cache.delete(cacheKey);
            }, cacheTimeout);
          }

          return response;
        });
    }

    return this.client.request<T>(config);
  }

  private static _isCacheableMethod(method: string): boolean {
    return method === 'get' || method === 'post';
  }

  private static _getCacheKey(config: AxiosRequestConfig): string {
    const url = config.url;
    const params = JSON.stringify(config.params);
    const data = JSON.stringify(config.data);
    return `${url}_${params}_${data}`;
  }

  private static _isCacheValid(timestamp: number, cacheTime: number): boolean {
    const now = Date.now();
    const expirationTime = timestamp + cacheTime;
    return now < expirationTime;
  }
}
