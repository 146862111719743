import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type {
  PaymentMethod,
  PaymentsLimitUsersResponse,
  PaymentsLimitPage
} from './schema';
import type { AxiosPromise, AxiosResponse } from 'axios';

/**
 * Get payment methods
 * @return Promise
 */
export async function getPaymentMethods(): Promise<PaymentMethod[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/payment-method/all`))
    .then(({ data }: AxiosResponse): PaymentMethod[] => data);
}

/**
 * Get payment methods
 * @return Promise
 */
export async function updatePaymentMethodStatus(
  payload: PaymentMethod
): Promise<PaymentMethod> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/payment-method/active`), null, {
      params: { active: payload.active, id: payload.id }
    })
    .then(({ data }: AxiosResponse): PaymentMethod => data);
}

/**
 * Update payment methods
 * @return Promise
 */
export async function updatePaymentMethod(
  payload: PaymentMethod
): Promise<PaymentMethod> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/payment-method/edit`), payload)
    .then(({ data }: any): PaymentMethod => data);
}

/**
 * Update payment methods from superadmin.
 * @return Promise
 */
export async function updateSuperadminPaymentMethod(
  payload: PaymentMethod
): Promise<PaymentMethod> {
  return http
    .post(
      buildUrl(`/api/v1/super-admin/payment-method/{operatorId}/edit`),
      payload
    )
    .then(({ data }: any): PaymentMethod => data);
}

/**
 * Enable dynamic deposit limit (max deposit = Play 2 earn + hold 2 earn - current)
 * @return Promise
 */
export async function enableDynamicDepositLimit(
  enabled: boolean
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/operator/{operatorId}/initial-deposit-limit`),
    null,
    { params: { enabled } }
  );
}

/**
 * Enable dynamic deposit limit by super admin.
 * @return Promise
 */
export async function enableSuperAdminDynamicDepositLimit(
  enabled: boolean
): AxiosPromise<PaymentMethod> {
  return http.post(
    buildUrl(
      `/api/v1/super-admin/operators/{operatorId}/initial-deposit-limit`
    ),
    null,
    { params: { enabled }, cacheTime: 0 }
  );
}

/**
 * get users with individual payment limits.
 * @return Promise
 */
export async function getUsersPaymentLimits(
  data: PaymentsLimitPage
): Promise<PaymentsLimitUsersResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/payment-method/individual/all`), {
      params: data
    })
    .then(({ data }: AxiosResponse): PaymentsLimitUsersResponse => data);
}

/**
 * Get initial deposit limit status as superadmin.
 * @return Promise with status
 */
export async function getSuperAdminDynamicDepositLimitStatus(): Promise<boolean> {
  return http
    .get(
      buildUrl(
        `/api/v1/super-admin/operators/{operatorId}/initial-deposit-limit`
      ),
      { cacheTime: 0 }
    )
    .then(({ data }: AxiosResponse<boolean>): boolean => data);
}

/**
 * Get initial deposit limit status.
 * @return Promise with status
 */
export async function getDynamicDepositLimitStatus(): Promise<boolean> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/info/initial-deposit-limit`), {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<boolean>): boolean => data);
}
