import store from '@/store/index';

export const buildUrl = (
  url: string,
  externalOperatorId: number | null = null
): string => {
  const operatorId =
    externalOperatorId ||
    store.getters['Onboarding/operatorId'] ||
    store.state.Auth.operatorId ||
    store.state.SuperAdmin.operatorId;

  return url.replace(/{operatorId}/g, operatorId);
};
