import {
  ArrayItem,
  OperatorWalletType,
  IAccountListItem,
  AccountOperator
} from '@/api/schema';
import { capitalization } from '@/helpers/formatString';

export const Status = {
  declined: 'DECLINED',
  on: 'ON',
  off: 'OFF',
  created: 'CREATED'
};

// TODO: fix - crutch for not showing operator payments feature on prod, feature flags not prepared yet
export const enableOperatorPayments = ['.stage.', '.dev.', 'localhost'].some(
  (str) => location.hostname.toLowerCase().includes(str)
);

export const OperatorWalletTypesArray: ArrayItem[] = Object.values(
  Object(OperatorWalletType)
).map((v) => {
  const str = String(v);
  return {
    value: str,
    text: str
      .split('_')
      .map((v) => capitalization(v))
      .join(' ')
  };
});

export const activeOperators: Readonly<{ label: string; id: number }[]> = [
  { label: 'Fairspin.io', id: 1 },
  { label: 'Weiss.bet', id: 2875 }
  // { label: 'bahsegel.com', id: 3467 },
  // { label: 'slotscity.com', id: 3599 },
  // { label: 'elonbet.com', id: 5814 },
  // { label: 'azurebet.com', id: 6738 },
  // { label: 'juegalo.com', id: 5812 },
  // { label: 'belparyaj.com', id: 6439 },
  // { label: 'smokace.com', id: 7231 },
  // { label: 'vemapostar.com', id: 7245 }
];

export const formatBrandName = (value: string): string => {
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/.]+)/i;
  const match = value?.match(domainRegex);

  return match ? match[1] : '';
};

export const groupOperatorsByBrandName = (
  list: AccountOperator[],
  divider: boolean = false
): { [key: string]: AccountOperator } => {
  return list.reduce(
    (acc, operator) => ({
      ...acc,
      [capitalization(operator.brandName || 'Other')]: [
        ...(acc[capitalization(operator.brandName || 'Other')] ||
          (divider ? [{ divider: true }] : [])),
        {
          ...operator,
          name: capitalization(operator.name)
        }
      ]
    }),
    {}
  );
};

export const groupOperatorsByAccounts = (
  list: AccountOperator[]
): IAccountListItem[] => {
  return Object.entries(groupOperatorsByBrandName(list)).map(
    ([key, value]) => ({
      name: capitalization(key),
      children: value
    })
  );
};
