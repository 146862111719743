






























import VDataTableFooter from '@/components/v-data-table/VDataTableFooter.vue';
import { PropType } from 'vue';
import { DataOptions } from 'vuetify';
import VDataTableSelectDialog from '@/components/v-data-table/VDataTableSelectDialog.vue';

export default {
  inheritAttrs: false,
  components: {
    VDataTableSelectDialog,
    VDataTableFooter
  },

  props: {
    options: {
      type: Object as PropType<Partial<DataOptions>>,
      default: (): Partial<DataOptions> => ({
        page: 1,
        itemsPerPage: 10
      })
    },
    itemsPerPageOptions: {
      type: Array,
      default: (): number[] => [10, 20, 50]
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showSelectDialog: {
      type: Boolean,
      default: false
    },
    selectLabels: {
      type: Array,
      default: (): string[] => ['item', 'items', 'items']
    },
    disableItemsPerPage: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      localOptions: this.options,
      selected: [],
      expanded: []
    };
  },

  methods: {
    setSelected(value: any[]): void {
      this.selected = value;
      this.$emit('input', value);
    }
  }
};
