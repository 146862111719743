





























import { PropType } from 'vue';
import type {
  DataPagination,
  DataItemsPerPageOption,
  DataOptions
} from 'vuetify';

export default {
  name: 'VDataTableFooter',

  props: {
    options: {
      type: Object as PropType<Partial<DataOptions>>,
      default: (): any => ({
        page: 1
      })
    },
    pagination: {
      type: Object as PropType<DataPagination>,
      default: (): any => ({
        pageCount: 0
      })
    },
    itemsPerPageOptions: {
      type: Array as PropType<DataItemsPerPageOption[]>,
      required: true
    },
    disableItemsPerPage: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showFooter(): boolean {
      return !this.disableItemsPerPage || this.pagination.pageCount > 1;
    }
  },

  methods: {
    updatePagination(option: { [key: string]: number }): void {
      this.$emit('update:options', {
        ...this.options,
        ...option
      });
    }
  }
};
