import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type {
  MonthlyBuybacksStats,
  BlockchainTransactions,
  BlockchainWallet,
  BlockchainWalletData,
  BuybackSettings,
  TransactionsPageData,
  TradingSettings,
  BuybacksDataRequest,
  BuybacksListResponse,
  LiquiditySettings,
  BlockchainWalletReportRequest,
  FirstBuybackDayResponse,
  BlockchainTransactionsSummary,
  LiquidityInfo,
  ILiquidityTransactionsRequestParams,
  TLiquidityTransactionItem,
  ITradingDetailsParams,
  ITradingDetailsResponse
} from './schema';

/**
 * Get blockchain wallet.
 * @return Promise with operator wallets.
 */
export async function getWallet(operatorId: string): Promise<BlockchainWallet> {
  return http
    .get(`/api/v1/${operatorId}/blockchain-wallet/all`, {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): BlockchainWallet => data);
}

/**
 * Create blockchain wallet.
 */
export async function createWallet(
  data: BlockchainWalletData
): AxiosPromise<BlockchainWallet> {
  return http.post(buildUrl('/api/v1/{operatorId}/blockchain-wallet'), {
    ...data
  });
}

/**
 * Edit blockchain wallet.
 */
export async function editWallet(
  data: BlockchainWalletData
): Promise<BlockchainWallet> {
  return http.put(buildUrl('/api/v1/{operatorId}/blockchain-wallet'), {
    ...data
  });
}

/**
 * delete blockchain wallet
 * @param id wallet id
 */

export async function deleteWallet(id: number): Promise<void> {
  return http.delete(buildUrl(`/api/v1/{operatorId}/blockchain-wallet/${id}`));
}

/**
 * Change active status of blockchain wallet.
 * @return Promise with operator wallets.
 */
export async function activateWallet(
  id: number,
  active: boolean
): Promise<BlockchainWallet> {
  return http
    .post(
      buildUrl(`/api/v1/{operatorId}/blockchain-wallet/${id}/active`),
      null,
      {
        params: { active }
      }
    )
    .then(({ data }: AxiosResponse): BlockchainWallet => data);
}

/**
 * Get blockchain buyback wallet settings
 * @return Promise with settings
 */

export async function buybackSettings(): Promise<BuybackSettings> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/blockchain-wallet/buyback-settings`))
    .then(({ data }: AxiosResponse): BuybackSettings => data);
}

/**
 * Save buyback settings
 * @param settings object with settings
 * @return Promise with settings
 */

export async function saveBuybackSettings(
  settings: BuybackSettings
): Promise<BuybackSettings> {
  return http
    .post(buildUrl('/api/v1/{operatorId}/blockchain-wallet/buyback-settings'), {
      ...settings
    })
    .then(({ data }: AxiosResponse): BuybackSettings => data);
}

/**
 * Set buyback volume for operator.
 * @return Promise with status response.
 * @param buybackVolume
 */

export async function setBuybackVolume(
  buybackVolume: number
): AxiosPromise<BuybackSettings> {
  return http.post(
    buildUrl('/api/v1/super-admin/operators/{operatorId}/buyback-volume'),
    null,
    {
      params: { buybackVolume }
    }
  );
}

/**
 * Get trading settings
 * @return Promise with trading settings
 */

export async function tradingSettings(): Promise<TradingSettings> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/blockchain-wallet/trading-settings'))
    .then(({ data }: AxiosResponse): TradingSettings => data);
}

/**
 * Save trading settings
 * @param settings object with trading settings
 * @return Promise with trading settings
 */

export async function saveTradingSettings(
  settings: TradingSettings
): Promise<TradingSettings> {
  return http
    .post(buildUrl('/api/v1/{operatorId}/blockchain-wallet/trading-settings'), {
      ...settings
    })
    .then(({ data }: AxiosResponse): TradingSettings => data);
}

/**
 * Get liquidity settings.
 * @return Promise with liquidity settings.
 */

export async function liquiditySettings(): Promise<LiquiditySettings> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/blockchain-wallet/liquidity-settings'))
    .then(({ data }: AxiosResponse): LiquiditySettings => data);
}

/**
 * Save liquidity settings
 * @param settings object with liquidity settings
 * @return Promise with liquidity settings
 */

export async function saveLiquiditySettings(
  settings: LiquiditySettings
): Promise<LiquiditySettings> {
  return http
    .post(
      buildUrl('/api/v1/{operatorId}/blockchain-wallet/liquidity-settings'),
      {
        ...settings
      }
    )
    .then(({ data }: AxiosResponse): LiquiditySettings => data);
}

/**
 * Get Daily Buybacks Main Statistic.
 * @return Promise with stats.
 */
export async function getBuybackStats(): Promise<MonthlyBuybacksStats> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/daily-buyback/statistic`))
    .then(({ data }: AxiosResponse): MonthlyBuybacksStats => data);
}

/**
 * Get blockchain wallets transactions.
 * @return Promise with transactions.
 */
export async function getTransactions(
  data: TransactionsPageData
): Promise<BlockchainTransactions> {
  return http
    .get(
      buildUrl(
        `/api/v1/{operatorId}/blockchain-wallet/${data.type}-transaction`
      ),
      { params: { page: data.page, size: data.size, date: data.date } }
    )
    .then(({ data }: AxiosResponse): BlockchainTransactions => data);
}

/**
 * Get blockchain wallets transactions summary by date.
 * @return Promise with summary.
 */
export async function getTransactionsSummary(
  data: TransactionsPageData
): Promise<BlockchainTransactionsSummary> {
  return http
    .get(
      buildUrl(
        `/api/v1/{operatorId}/blockchain-wallet/${data.type}-transaction/summary`
      ),
      { params: { date: data.date } }
    )
    .then(({ data }: AxiosResponse): BlockchainTransactionsSummary => data);
}

/**
 * Get Daily Buybacks.
 * @return Promise with stats.
 */
export async function getBuybacksList(
  requestData: BuybacksDataRequest
): Promise<BuybacksListResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/daily-buyback/all`), {
      params: {
        ...requestData
      }
    })
    .then(({ data }: AxiosResponse): BuybacksListResponse => data);
}

/**
 * Get transactions csv report.
 * @return Promise with transactions csv report.
 */
export async function getBuybacksCSVReport(
  requestData: BuybacksDataRequest
): Promise<any> {
  return http.get(buildUrl(`/api/v1/{operatorId}/daily-buyback/all/csv`), {
    params: requestData
  });
}

/**
 * Get Buybacks csv report.
 * @return Promise with transactions csv report.
 */
export async function getBuybacksTransactionCSVReport(
  requestData: BlockchainWalletReportRequest
): Promise<any> {
  return http.get(
    buildUrl(`/api/v1/{operatorId}/blockchain-wallet/buyback-transaction/csv`),
    {
      params: requestData
    }
  );
}

/**
 * Get Trading csv report.
 * @return Promise with transactions csv report.
 */
export async function getTradingTransactionCSVReport(
  requestData: BlockchainWalletReportRequest
): Promise<any> {
  return http.get(
    buildUrl(`/api/v1/{operatorId}/blockchain-wallet/trading-transaction/csv`),
    {
      params: requestData
    }
  );
}

/**
 * Get first date of buyback.
 * @return Promise with first date of buyback
 */
export async function getFirstBuybackDate(): Promise<FirstBuybackDayResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/daily-buyback/first-date`))
    .then(({ data }: AxiosResponse): FirstBuybackDayResponse => data);
}

/**
 * Get liquidity settings.
 * @return Promise with liquidity settings.
 */
export async function createAddLiquidityPool(
  tokenPrice: number,
  amountUsdt: number
): Promise<any> {
  return http.post(
    buildUrl(
      '/api/v1/super-admin/operators/{operatorId}/create-liquidity-pool-and-add'
    ),
    null,
    {
      params: { tokenPrice, amountUsdt }
    }
  );
}

export async function addUsdtLiquidityPool(usdtAmount: number): Promise<any> {
  return http.post(
    buildUrl('/api/v1/super-admin/operators/{operatorId}/add-liquidity'),
    null,
    {
      params: { usdtAmount }
    }
  );
}

export async function removeUsdtLiquidityPool(
  usdtAmount: number
): Promise<any> {
  return http.post(
    buildUrl('/api/v1/super-admin/operators/{operatorId}/remove-liquidity'),
    null,
    {
      params: { usdtAmount }
    }
  );
}

export async function getLiquidityInfo(): Promise<LiquidityInfo> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/liquidity/get-liquidity-info'))
    .then(({ data }: AxiosResponse): LiquidityInfo => data);
}

export async function getLiquidityTransactionsSum(): Promise<number> {
  return http
    .get(
      buildUrl('/api/v1/{operatorId}/liquidity/get-liquidity-transactions-sum')
    )
    .then(({ data }: AxiosResponse): number => data);
}

export async function getLiquidityTransactions(
  requestData: ILiquidityTransactionsRequestParams
): Promise<TLiquidityTransactionItem[]> {
  return http
    .get(
      buildUrl('/api/v1/{operatorId}/liquidity/get-liquidity-transactions'),
      { params: requestData }
    )
    .then(({ data }: AxiosResponse): TLiquidityTransactionItem[] => data);
}

export async function savePancakeLimit(amount: number): Promise<void> {
  return http
    .patch(
      buildUrl(`/api/v1/super-admin/operators/{operatorId}/pancake-max-amount`),
      null,
      { params: { amount } }
    )
    .then(({ data }: AxiosResponse): void => data);
}

/**
 * Get Daily Trading details.
 * @return Promise with stats.
 */
export async function getTradingDetailsList(
  requestData: ITradingDetailsParams
): Promise<ITradingDetailsResponse> {
  return http
    .get(
      buildUrl(
        `/api/v1/{operatorId}/blockchain-wallet/trading-transaction/details`
      ),
      {
        params: requestData
      }
    )
    .then(({ data }: AxiosResponse): ITradingDetailsResponse => data);
}

/**
 * Get transactions csv report.
 * @return Promise with transactions csv report.
 */
export async function getTradingDetailsCSVReport(
  requestData: ITradingDetailsParams
): Promise<any> {
  return http.get(
    buildUrl(
      `/api/v1/{operatorId}/blockchain-wallet/trading-transaction/details/csv`
    ),
    {
      params: requestData
    }
  );
}
