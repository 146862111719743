import { DateTime } from 'luxon';
import { FORMAT_DATE } from '@/helpers/copyStakeHelpers';

const url = window.location.hostname;
export const isGameProviderAdmin =
  url.includes('games-admin') || localStorage.getItem('games-admin') === 'true';

export const MIN_DATE = DateTime.fromJSDate(new Date('2023-10-1')).toFormat(
  FORMAT_DATE
);
