import type { AuthTokenData, IAuthInviteTokenData } from '@/api/schema';
import jwt_decode from 'jwt-decode';
import { ERole } from '@/plugins/role-permissions/types';

export const getRolesArray = (
  roles: string[]
): { value: string; label: string }[] =>
  roles.map((value) => {
    let label = value.replace('ROLE_OPERATOR_', '').toLowerCase();
    label = label[0].toUpperCase() + label.slice(1);

    return {
      label,
      value
    };
  });

export const parsedToken = (token: string): AuthTokenData => jwt_decode(token);

export const getRole = (token: string): string | null => {
  if (!token) return null;
  return parsedToken(token)?.role || null;
};

export const getOperatorId = (token: string): number | null => {
  if (!token) return null;
  return parsedToken(token)?.id_operator || null;
};

export const getAccountId = (token: string): number | null => {
  if (!token) return null;
  return parsedToken(token)?.id_account || null;
};

export const getRoleLabel = (value: string): string | null => {
  if (!value) {
    return '-';
  }

  return (
    getRolesArray(Object.values(ERole)).find((v) => v.value === value)?.label ||
    '-'
  );
};

export const getMsToTokenExpire = (targetToken?: string): number => {
  try {
    const now = new Date().getTime();
    const { exp }: AuthTokenData = parsedToken(targetToken);

    return Math.max(exp * 1e3 - now, 0);
  } catch (err) {
    return 0;
  }
};

export const parseInviteToken = <T = IAuthInviteTokenData>(
  token: string
): Partial<T> => (token ? jwt_decode(token) : {});
