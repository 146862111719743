import { http } from '@/api/Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  NewMobileEvent,
  MobileEventItem,
  ResponseObject
} from '@/api/schema';

/**
 * get mobile event
 * @return array with mobile events
 */

export async function mobileEvent(
  page: number,
  size: number,
  sort: string
): Promise<ResponseObject<MobileEventItem>> {
  return http
    .get(buildUrl('/api/v1/operator/{operatorId}/mobile-events'), {
      params: {
        sort,
        page,
        size
      }
    })
    .then(
      ({
        data
      }: AxiosResponse<
        ResponseObject<MobileEventItem>
      >): ResponseObject<MobileEventItem> => data
    );
}

/**
 * Function for create mobile event
 * @param event object with mobile params
 * @return data object with mobile event
 */

export async function createMobileEvent(
  event: NewMobileEvent
): Promise<MobileEventItem> {
  return http
    .post(buildUrl('/api/v1/operator/{operatorId}/mobile-events'), {
      ...event
    })
    .then(({ data }: AxiosResponse<MobileEventItem>): MobileEventItem => data);
}

/**
 * Function for edit mobile event
 * @param eventId number event id
 * @param event object with new params for mobile event
 */

export async function editMobileEvent(
  eventId: number,
  event: NewMobileEvent
): Promise<MobileEventItem> {
  return http
    .patch(buildUrl(`/api/v1/operator/{operatorId}/mobile-events/${eventId}`), {
      ...event
    })
    .then(({ data }: AxiosResponse<MobileEventItem>): MobileEventItem => data);
}
