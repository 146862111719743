




























import { separate, isInteger } from '@/helpers/decimalsHelpers';

export default {
  model: { prop: 'value', event: 'input' },
  name: 'NumericField',
  props: {
    value: {
      type: [String, Number],
      default: '0'
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    minlength: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    hint: {
      type: String,
      required: false,
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cmpRules(): any {
      if (!this.rules) {
        return null;
      }

      return this.rules.map((rule) => {
        return () => rule(this.machineFormat(this.cmpValue));
      });
    },
    cmpValue: {
      get(): string {
        return this.humanFormat(this.value);
      },
      set(newValue: string): void {
        this.$emit('input', this.machineFormat(newValue));
      }
    }
  },

  methods: {
    humanFormat(value: string | number): any {
      if (value || value === 0) {
        value = separate(value);
      } else {
        value = null;
      }
      return value;
    },
    machineFormat(value: string): any {
      if (value) {
        value = this.clearNumber(value);
        if (value === '') {
          value = null;
        }
      } else {
        value = null;
      }
      return value;
    },
    clearNumber(value: string): string {
      let result = '';
      if (value) {
        let hasDot = false;

        let arrayValue = value.toString().split('');
        for (let i = arrayValue.length - 1; i >= 0; i--) {
          const char = arrayValue[i];

          if (isInteger(char)) {
            result = char + result;
          } else {
            if (!hasDot && char === '.') {
              hasDot = true;
              result = char + result;
            } else if (char === '-' && !i) {
              result = char + result;
            } else if (char !== ' ') {
              return value;
            }
          }
        }
      }

      return result;
    },
    keyPress($event: KeyboardEvent): void {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // 46 is a dot, 45 is a minus

      const value = ($event.target as HTMLFormElement).value;
      if (keyCode === 46) {
        if (value.includes('.')) {
          return $event.preventDefault();
        }
      } else if (keyCode === 45) {
        if (value) {
          return $event.preventDefault();
        }
      } else if (keyCode < 48 || keyCode > 57) {
        return $event.preventDefault();
      }

      this.$emit('keypress');
    }
  }
};
