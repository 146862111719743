import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  OperatorBalance,
  OperatorWalletForDeposit,
  PageData,
  OperatorInvoiceHistory,
  OperatorBalanceOverallData
} from './schema';

/**
 * Get operator balance
 */
export async function getOperatorBalance(): Promise<OperatorBalance> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/balance`), {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): OperatorBalance => data);
}

/**
 * Get operator wallet for deposit
 */
export async function getOperatorWallet(
  isUsdt: boolean = false
): Promise<OperatorWalletForDeposit> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/custody/wallet`), {
      params: { 'is-usdt': isUsdt }
    })
    .then(({ data }: AxiosResponse): OperatorWalletForDeposit => data);
}

/**
 * Get operator balance invoice history.
 * @param payload invoice page data.
 */
export async function getOperatorInvoiceHistory(
  payload: PageData
): Promise<OperatorInvoiceHistory> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/balance/invoices`), {
      params: { ...payload }
    })
    .then(({ data }: AxiosResponse): OperatorInvoiceHistory => data);
}

/**
 * Get operator invoice PDF report.
 * @return Promise with invoice PDF report.
 * @param invoiceId Invoice id.
 * @param operatorId Operator id.
 */
export async function getOperatorInvoiceReportPdf(
  invoiceId: number,
  operatorId: number | null = null
): Promise<any> {
  return http.get(
    buildUrl(
      `/api/v1/{operatorId}/balance/invoice/${invoiceId}/pdf`,
      operatorId
    ),
    {
      responseType: 'blob'
    }
  );
}

/**
 * Get operator balance
 */
export async function getOperatorBalanceMovementReport(
  from: string,
  to: string
): Promise<OperatorBalanceOverallData> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/movement-report`), {
      params: { from, to },
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): OperatorBalanceOverallData => data);
}

/**
 * Get operator balance movement csv report.
 * @return Promise with invoice csv report.
 */
export async function getOperatorBalanceMovementReportCsv(
  from: string,
  to: string
): Promise<any> {
  return http.get(buildUrl(`/api/v1/{operatorId}/movement-report/csv`), {
    params: { from, to }
  });
}
