import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  StakingProgram,
  NewStakingProgramData,
  UpdateStakingProgramRequestData,
  GetStakingProgramsResponse
} from './schema';

/**
 * Get current staking programs.
 * @return Promise with staking programs data.
 */
export async function getStakingPrograms(): Promise<GetStakingProgramsResponse> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/stacking-program`))
    .then(({ data }: AxiosResponse): GetStakingProgramsResponse => data);
}

/**
 * Delete staking program by id.
 * @param id Number.
 */
export async function deleteStakingProgram(id: number): Promise<any> {
  return http
    .delete(buildUrl(`/api/v1/{operatorId}/stacking-program/${id}`))
    .then(({ data }: AxiosResponse): any => data);
}

/**
 * Create staking programs.
 * @param payload New StakingProgram data.
 * @return Promise with new staking program data.
 */
export async function createStakingProgram(
  payload: NewStakingProgramData
): Promise<StakingProgram> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/stacking-program`), {
      ...payload
    })
    .then(({ data }: AxiosResponse): StakingProgram => data);
}

/**
 * Update staking program by id.
 * @param id Number.
 * @param data New StakingProgram data.
 * @return Promise with new staking program data.
 */
export async function updateStakingProgram(
  payload: UpdateStakingProgramRequestData
): Promise<StakingProgram> {
  const { id, data } = payload;
  return http
    .put(buildUrl(`/api/v1/{operatorId}/stacking-program/${id}`), { ...data })
    .then(({ data }: AxiosResponse): StakingProgram => data);
}

/**
 * Start all staking programs.
 * @param id Number.
 */
export async function startAllStakingProgram(): Promise<any> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/stacking-program/start-all`))
    .then(({ data }: AxiosResponse): any => data);
}

/**
 * Stop all staking programs.
 * @param id Number.
 */
export async function stopAllStakingProgram(): Promise<any> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/stacking-program/stop-all`))
    .then(({ data }: AxiosResponse): any => data);
}

/**
 * Set default coefficient value
 * @param value Coefficient value.
 * @return Promise
 */
export async function setDefaultCoefficient(value: number): Promise<any> {
  return http.patch(
    buildUrl(`/api/v1/{operatorId}/users/coefficient/${value}`)
  );
}
